.timeline {
    list-style-type: none;
    position: relative;

    &:before {
        content: ' ';
        background: $primary;
        display: inline-block;
        position: absolute;
        left: 29px;
        width: 2px;
        height: 100%;
        z-index: 400;
    }

    li {
        margin: 0;
        padding-left: 20px;

        &:before {
            content: ' ';
            background: $primary;
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 3px solid $primary;
            left: 20px;
            width: 20px;
            height: 20px;
            z-index: 400;
        }
    }
}

@each $color, $value in $theme-colors {
  .timeline-#{$color} {
    &:before {
      background: $value;
    }
    li:before {
      background: $value;
      border: 3px solid $value;
    }
  }
}


// Timeline by mylastof https://bootsnipp.com/snippets/xrKXW