// stylelint-disable declaration-no-important

@each $color, $value in $theme-colors {
  .bg-#{$color} {
    background-color: $value !important;
  }
  .bg-#{$color}-faded {
    background-color: rgba($value, 0.2) !important;
  }
}

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
  }
}

.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}
