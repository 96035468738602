.accordion {
    width: 70%;
    margin: auto;
    display: flex;
    margin-top: .4em;
    margin-bottom: .4em;
    border: none;
    border-radius: 0;
}

.accordion .card-title {
    float: left;
}

.accordion .card {
    border: none;
    border-radius: 0;
}

.accordion .card-body {
    background-color: $gray-300;
    margin-bottom: .4em;
    border: none;
    border-radius: 0;
}

.accordion .card-header {
    width: 70vw;
    transition: .3s;
    margin-top: .4em;
    border:none;
    border-radius: 0;

    background-color: $secondary;
    color: $white;
}

.accordion .card-header.collapsed {
    background-color: $gray-800;
    color: $white;
}

.accordion .card-header.collapsed:hover {
    background-color: $secondary;
}

.accordion .card-header:after {
    content: "-";
    font-weight: 600;
    font-size: 18pt;
    float: right;
    padding-right: 10px;
}

.accordion .card-header.collapsed:after {
    content: "+";
}
